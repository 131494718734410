<template>
  <div>
    <el-dialog top="12vh" width="580px" visible append-to-body custom-class="rounded" :show-close="false">
      <template #title>
        <div class="p-4 border-bottom">
          <div class="d-flex justify-content-between align-items-center">
            <span>
              <h2>
                {{ $t('payment.addBankAccountModal.title') }}
              </h2>
              <p v-if="supplierName">
                {{ supplierName }}
              </p>
            </span>
            <span>
              <Button type="text" class="p-0 text-typography-primary action-btn" @click="close">
                <CloseIcon />
              </Button>
            </span>
          </div>
        </div>
      </template>
      <div class="p-4">
        <el-form ref="form" :model="formModel" :show-message="false" class="mb-4">
          <el-form-item prop="bankAccount.accountName" class="m-0" required>
            <small class="text-muted">
              {{ $t('payment.addBankAccountModal.bankAccountName') }}
            </small>
            <el-input v-model="formModel.bankAccount.accountName" />
          </el-form-item>
          <div class="row g-0">
            <el-form-item
              v-if="countryCode === 'IL'"
              prop="bankAccount.bankNumber"
              class="col-7 m-0"
              :class="$t('direction') === 'ltr' ? 'pe-4' : 'ps-4'"
              :error="checksumError ? 'invalid' : null"
              required
            >
              <small class="text-muted">
                {{ $t('payment.addBankAccountModal.bank') }}
              </small>
              <el-select v-model="formModel.bankAccount.bankNumber" filterable class="w-100">
                <el-option
                  v-for="(bankName, bankNumber) in supportedBanks"
                  :key="bankNumber"
                  :value="bankNumber"
                  :label="`${bankNumber} - ${bankName}`"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="countryCode === 'IL'"
              prop="bankAccount.branchNumber"
              class="col-2 m-0"
              :class="$t('direction') === 'ltr' ? 'pe-4' : 'ps-4'"
              :error="checksumError ? 'invalid' : null"
              required
            >
              <small class="text-muted">
                {{ $t('payment.addBankAccountModal.bankBranchNumber') }}
              </small>
              <el-input v-model="formModel.bankAccount.branchNumber" type="number" />
            </el-form-item>

            <el-form-item
              v-if="countryCode === 'US'"
              prop="bankAccount.routingNumber"
              class="col-2 m-0"
              :class="$t('direction') === 'ltr' ? 'pe-4 col-6 m-0' : 'ps-4 col-6 m-0'"
              :error="checksumError ? 'invalid' : null"
              :rules="{
                required: true,
                len: 9,
                trigger: 'change',
              }"
            >
              <small class="text-muted">
                {{ $t('payment.addBankAccountModal.routingNumber') }}
              </small>
              <el-input v-model="formModel.bankAccount.routingNumber" type="text" @input="filterScientificNotation" />
            </el-form-item>
            <el-form-item
              prop="bankAccount.accountNumber"
              :class="countryCode === 'IL' ? 'col-3 m-0' : 'col-6 m-0'"
              :error="checksumError ? 'invalid' : null"
              required
            >
              <small class="text-muted">
                {{ $t('payment.addBankAccountModal.bankAccountNumber') }}
              </small>
              <el-input
                v-model="formModel.bankAccount.accountNumber"
                @input="() => (duplicateAccountOverride = false)"
              />
            </el-form-item>

            <div v-if="checksumError" class="mt-2">
              <div class="d-flex align-items-center">
                <ErrorFullIcon />
                <p class="red" :class="$t('direction') === 'ltr' ? 'ms-2' : 'me-2'">
                  {{ $t('payment.addBankAccountModal.masavValidationFailed') }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-6">
            <h3 class="mb-3 text-typography-primary">{{ $t('payment.addBankAccountModal.reference') }}</h3>
            <p class="word-break">{{ $t('payment.addBankAccountModal.refDescriptionLineOne') }}</p>
            <p class="word-break">{{ $t('payment.addBankAccountModal.refDescriptionLineTwo') }}</p>
            <div class="my-4">
              <div v-if="fileUrl" class="d-flex position-relative pdf-preview" @click.stop="previewFile">
                <div class="file-preview position-absolute"></div>
                <embed
                  :src="`${fileUrl}#view=FitV&toolbar=0&statusbar=0&messages=0&navpanes=0&scrollbar=0`"
                  type="application/pdf"
                  width="120"
                />
              </div>
              <div v-else>
                <el-upload
                  v-loading="uploading"
                  action="#"
                  drag
                  accept="application/pdf"
                  :http-request="uploadFile"
                  :on-success="onUpload"
                  :before-remove="() => true"
                  :show-file-list="false"
                >
                  <div class="text-info">
                    <UploadIcon width="50px" height="50px" />
                  </div>
                  <div class="el-upload__text">
                    {{ $t('payment.addBankAccountModal.uploadFile.dropText') }}
                    <em>
                      {{ $t('payment.addBankAccountModal.uploadFile.clickText') }}
                    </em>
                  </div>
                </el-upload>
                <p class="text-center text-muted mt-2">
                  {{ $t('payment.addBankAccountModal.uploadFile.supportedFiles') }}
                </p>
              </div>
              <div v-if="fileUrl" class="d-flex align-items-center">
                <p>{{ fileName }}</p>
                <Button
                  type="link"
                  class="text-typography-primary"
                  :class="$t('direction') === 'ltr' ? 'ms-2' : 'me-2'"
                  @click="removeFile"
                >
                  <p class="remove-button">{{ $t('commons.remove') }}</p>
                </Button>
              </div>
              <div v-if="hasFailedValidation" class="mt-2">
                <div class="d-flex flex-column align-items-start">
                  <p class="red" :class="$t('direction') === 'ltr' ? 'ms-2' : 'me-2'">
                    <ErrorFullIcon />
                    {{ $t('payment.addBankAccountModal.validationsFailed') }}
                  </p>

                  <ul v-if="hasFailedValidation" class="d-flex flex-column align-items-start">
                    <li
                      v-for="validationName in getFailedValidationTranslations()"
                      :key="validationName"
                      class="red"
                      :class="$t('direction') === 'ltr' ? 'ms-2' : 'me-2'"
                    >
                      {{ $t(`payment.addBankAccountModal.validationErrors.${validationName}`) }}
                    </li>
                  </ul>
                </div>
                <el-form-item>
                  <el-checkbox v-model="formModel.bankAccount.manualValidation">
                    <p>{{ $t('payment.addBankAccountModal.manualValidation') }}</p>
                  </el-checkbox>
                </el-form-item>
              </div>
            </div>
          </div>
        </el-form>
        <div class="d-flex justify-content-end">
          <Button type="secondary" @click="close">
            {{ $t('commons.cancel') }}
          </Button>
          <Button v-loading="saving" @click="handleSavePaymentMethod">
            {{ $t('commons.save') }}
          </Button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      v-if="showFilePreview && fileUrl"
      top="2%"
      custom-class="rounded"
      visible
      :show-close="false"
      @close="showFilePreview = false"
    >
      <template #title>
        <div class="d-flex justify-content-between p-4">
          <h3>{{ fileName }}</h3>
          <Button type="text" class="p-0 text-typography-primary action-btn" @click="close">
            <CloseIcon />
          </Button>
        </div>
      </template>
      <div class="px-6" style="height: 80vh">
        <embed width="100%" height="100%" :src="fileUrl" type="application/pdf" />
      </div>
      <template #footer><div style="height: 8px"></div></template>
    </el-dialog>

    <ActionsModal
      v-if="!!duplicateAccountError"
      :dialog-type="DIALOG_TYPES.PRIMARY"
      :toggle-dialog="!!duplicateAccountError"
      :title="$t('createBusinessModal.confirmModal.title')"
      :content="
        $t('tasks.supplierTask.bankAccount.duplication', {
          supplier: duplicateAccountError.businessName,
        })
      "
      :confirm-button-text="$t('commons.proceed')"
      :cancel-button-text="$t('commons.cancel')"
      @on-cancel="
        () => {
          duplicateAccountError = null;
          duplicateAccountOverride = false;
        }
      "
      @on-close="
        () => {
          duplicateAccountError = null;
          duplicateAccountOverride = false;
        }
      "
      @on-confirm="
        () => {
          duplicateAccountOverride = true;
          handleSavePaymentMethod();
        }
      "
    ></ActionsModal>
  </div>
</template>

<script>
import { computed, reactive, ref } from 'vue';
import { filter, head, identity, keys, map, pipe, propEq, toPairs, values } from 'ramda';

import { Button, ActionsModal, DIALOG_TYPES } from '@/modules/core';
import { CloseIcon, UploadIcon, ErrorFullIcon } from '@/assets/icons';

import { useBankAccountCreate, getBankAccountFileUpload } from '../../compositions/bankAccount';

export default {
  components: { Button, CloseIcon, UploadIcon, ErrorFullIcon, ActionsModal },
  props: {
    initialPaymentMethod: { type: Object, default: () => null },
    supplierName: {
      type: String,
      default: null,
    },
    businessId: {
      type: String,
      required: true,
    },
    businessCompanyId: {
      type: String,
      required: true,
    },
    countryCode: {
      type: String,
      required: true,
    },
    supportedBanks: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { createBankAccount } = useBankAccountCreate();

    const failedValidations = reactive({
      documentType: false,
      bankName: false,
      bankNumber: false,
      companyNumber: false,
      routingNumber: false,
      branchNumber: false,
      accountNumber: false,
      emptyDocument: false,
    });
    const checksumError = ref(false);
    const duplicateAccountError = ref(null);
    const duplicateAccountOverride = ref(false);

    const hasFailedValidation = computed(() => values(failedValidations).some(identity));

    return {
      DIALOG_TYPES,
      hasFailedValidation,
      checksumError,
      duplicateAccountError,
      duplicateAccountOverride,
      failedValidations,
      fileUrl: ref(null),
      fileName: ref(null),
      getBankAccountFileUpload,
      createBankAccount,
      showFilePreview: ref(false),
      saving: ref(false),
      uploading: ref(false),
    };
  },
  data() {
    const formModel =
      this.countryCode === 'IL'
        ? {
            paymentMethod: 'bankTransfer',
            bankAccount: {
              accountName: '',
              accountNumber: '',
              bankNumber: '',
              branchNumber: '',
              manualValidation: false,
              filePath: '',
            },
          }
        : {
            paymentMethod: 'bankTransfer',
            bankAccount: {
              accountName: '',
              accountNumber: '',
              routingNumber: '',
              manualValidation: false,
              filePath: '',
            },
          };

    return {
      formModel,
    };
  },
  methods: {
    closeDuplicationModal() {
      this.duplicateAccountError = null;
    },
    getFailedValidationTranslations() {
      return pipe(toPairs, filter(propEq(1, true)), map(head))(this.failedValidations);
    },
    resetValidations() {
      for (const validationName of keys(this.failedValidations)) {
        this.failedValidations[validationName] = false;
      }
    },
    close() {
      this.$emit('close');
    },
    setValidationError({ code, details }) {
      switch (code) {
        case 1:
          this.checksumError = true;
          break;
        case 2:
          this.failedValidations.documentType = true;
          break;
        case 3:
          this.failedValidations.bankNumber = true;
          break;
        case 4:
          this.failedValidations.companyNumber = true;
          break;
        case 5:
          this.failedValidations.bankName = true;
          break;
        case 6:
          this.failedValidations.branchNumber = true;
          break;
        case 7:
          this.failedValidations.accountNumber = true;
          break;
        case 8:
          this.failedValidations.emptyDocument = true;
          break;
        case 9:
          this.failedValidations.routingNumber = true;
          break;
        case 10:
          this.duplicateAccountError = { ...details };
          break;

        default:
          break;
      }
    },
    async handleSavePaymentMethod() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.saving = true;
          this.resetValidations();
          this.checksumError = false;
          this.duplicateAccountError = null;
          const { accountName, bankNumber, branchNumber, accountNumber, manualValidation, filePath, routingNumber } =
            this.formModel.bankAccount;
          try {
            await this.createBankAccount(
              {
                createParams: {
                  businessId: this.businessId,
                  accountName,
                  ...(this.countryCode === 'IL' && { bankNumber }),
                  ...(this.countryCode === 'IL' && { branchNumber }),
                  ...(this.countryCode === 'US' && { routingNumber }),
                  accountNumber,
                  filePath,
                  manualValidation,
                  ...(this.duplicateAccountOverride && { duplicateAccountOverride: this.duplicateAccountOverride }),
                },
              },
              { refetchQueries: ['bankAccounts'] }
            );
            this.$message.success(this.$t('commons.messages.action.success'));
            this.close();
          } catch (err) {
            if (err.graphQLErrors[0]?.extensions?.response?.status === 409) {
              const { validationErrors } = err.graphQLErrors[0].extensions.response.body?.details ?? {};
              validationErrors?.forEach((error) => this.setValidationError(error));
            }
            this.formModel.bankAccount.manualValidation = false;
          }
        }
        this.saving = false;
      });
    },
    async uploadFile(request) {
      this.uploading = true;
      const { url, filePath, fields } = await getBankAccountFileUpload({
        businessId: this.businessId,
        filename: request.file.name,
      });

      const parsedFields = JSON.parse(fields);
      const formData = new FormData();
      Object.keys(parsedFields).forEach((key) => formData.append(key, parsedFields[key]));
      formData.append('file', request.file);

      const response = await fetch(url, { method: 'POST', body: formData });

      if (response.ok) request.onSuccess(filePath);
    },
    async onUpload(filePath, file) {
      try {
        if (!filePath) return;
        this.fileUrl = URL.createObjectURL(file.raw);
        this.fileName = file.name;
        this.formModel.bankAccount.filePath = filePath;
      } catch (err) {
        this.$message.error(`${err.error} - ${err.reason}`);
      }
      this.uploading = false;
    },
    removeFile() {
      this.fileUrl = null;
      this.fileName = null;
      this.formModel.bankAccount.manualValidation = false;
      this.resetValidations();
    },
    previewFile() {
      this.showFilePreview = true;
    },
    filterScientificNotation() {
      this.formModel.bankAccount.routingNumber = this.formModel.bankAccount.routingNumber.replace(/[^0-9]/g, '');
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .el-dialog .el-dialog__body,
::v-deep .el-dialog .el-dialog__header {
  padding: 0px;
}

::v-deep .el-dialog.action-modal .el-dialog__header {
  padding: 10px;
}

::v-deep .el-checkbox__label,
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: $typography-primary !important;
}

/* Chrome, Safari, Edge, Opera */
::v-deep input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
::v-deep input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

::v-deep .el-upload {
  width: 100%;
}

::v-deep .el-upload-dragger {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

.red {
  color: #e52044;
}

.file-preview {
  height: 142px;
  width: 100px;
  overflow: hidden;
}

.remove-button {
  color: #e52044;
  &:hover {
    text-decoration-color: #e52044;
  }
}

.pdf-preview {
  border: 1px solid $outline;
  cursor: pointer;
  height: fit-content;
  width: fit-content;
  overflow: hidden;
}

.word-break {
  word-break: break-word;
}
</style>
